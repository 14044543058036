import React from 'react';
import { Link } from 'gatsby';
import LanguageSwitcher from './LanguageSwitcher';
import PropTypes from 'prop-types';
import AOS from 'aos';
import Cookies from './Cookies';

class Layout extends React.Component {
  componentDidMount() {
    if (this.props.initAOS) {
      AOS.init({
        disable: 'mobile',
        initClassName: 'aos-init',
        animatedClassName: 'aos-animate',
        duration: 800,
        once: true,
        mirror: false,
        anchorPlacement: 'top-bottom',
      });
    }
  }

  render() {
    const { location, config, children, translations } = this.props;
    let header;

    if (`${__PATH_PREFIX__}${config.fields.slug}` === location.pathname) {
      header = (
        <h1>
          <Link to={config.fields.slug}>{config.frontmatter.title}</Link>
        </h1>
      );
    } else {
      header = (
        <h3>
          <Link to={'/'}>{config.frontmatter.title}</Link>
        </h3>
      );
    }

    return (
      <div>
        <LanguageSwitcher
          language={config.frontmatter.language}
          translations={translations}
        />

        {header}
        {children}

        <Cookies />
      </div>
    );
  }
}

export default Layout;

Layout.propTypes = {
  initAOS: PropTypes.bool,
};
