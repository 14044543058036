import React from 'react';
import localForage from 'localforage';
import CloseIcon from './SVG/CloseIcon';

class Cookies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCookieBarVisible: false,
    };
  }

  componentDidMount() {
    localForage
      .getItem('hideCookies')
      .then(() => {
        return localForage.getItem('hideCookies');
      })
      .then(value => {
        !value ? this.setState({ isCookieBarVisible: true }) : null;
      });
  }

  closeCookies = () => {
    localForage
      .setItem('hideCookies', true)
      .then(() => {
        return localForage.getItem('hideCookies');
      })
      .then(value => {
        this.setState({ isCookieBarVisible: !value });
      });
  };

  render() {
    const { isCookieBarVisible } = this.state;
    return (
      isCookieBarVisible && (
        <div className='cookies is-shown'>
          <div className='container'>
            <p className='cookies-text'>
              Ta strona używa ciasteczek (cookies) zgodnie z Polityką
              Prywatności. W Twojej przeglądarce możesz zmienić ustawienia
              dotyczące przechowywania lub dostępu do cookies.
            </p>
            <button className='cookies-close' onClick={this.closeCookies}>
              <CloseIcon />
            </button>
          </div>
        </div>
      )
    );
  }
}

export default Cookies;
