import React from 'react';

class Bio extends React.Component {
  render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
}

export default Bio;
